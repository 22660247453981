import { AdminUpdateInput } from "services/admin/admin/types";
import { SuccessResponse } from "types";
import { decryptWithAES, encryptWithAES } from "utils/parse";
import http from "../index";
import {
  AdminType,
  LoginData,
  LoginResponse,
  PasswordUpdateInput,
  User,
} from "./types";

namespace auth {
  const userKey = "app.user";
  const tokenKey = "app.token";

  export const saveToken = (token: string) => {
    localStorage.setItem(tokenKey, token);
  };

  export const rememberUser = (values: LoginData) => {
    if (values.remember) {
      localStorage.setItem(userKey, encryptWithAES(JSON.stringify(values)));
    } else {
      localStorage.removeItem(userKey);
    }
  };

  export const getRememberUser = () => {
    const userData = localStorage.getItem(userKey);
    if (userData) {
      const _userData = JSON.parse(decryptWithAES(userData)) as LoginData;
      return _userData;
    }
    return undefined;
  };

  export const remToken = () => {
    localStorage.removeItem(tokenKey);
  };

  export const hasToken = () => !!localStorage.getItem(tokenKey);

  export const getToken = () => localStorage.getItem(tokenKey);

  export const info = () =>
    http.get<User>("admin/auth/info", { hasAuth: true });

  export const update = (body: AdminUpdateInput) =>
    http.post<SuccessResponse>("admin/auth/update", { hasAuth: true, body });

  export const login = (body: LoginData) =>
    http.post<LoginResponse>("admin/auth/login", {
      body,
    });

  export const changePassword = (body: PasswordUpdateInput) =>
    http.put<SuccessResponse>(`admin/auth/password_change`, {
      body,
      hasAuth: true,
    });

  export const getMenus = (items: any[], role?: AdminType) => {
    const filtered: any[] = [];
    // is admin
    if (role === "system_admin") {
      return items;
    }

    if (role?.includes("merchant")) {
      return items.filter((item) => item.type?.includes("merchant"));
    }

    return filtered;
  };

  export const reDirect = (role?: AdminType) => {
    // is admin
    if (role === "system_admin") {
      return "/dashboard/home";
    }

    if (role === "system_manager") {
      return "/dashboard/404";
    }

    if (role?.includes("merchant")) {
      return "/dashboard/organization";
    }

    return "/dashboard/404";
  };
}

export default auth;
